<template>
  <section :id="`${anchor ? anchor : 'block-' + indexBlock}`" class="block-about-us pb-5 pt-lg-125"
  :class="getContainerBackground(getBackground, getBacteria)"
  >   
    <div class="container">
      <div class="row g-5 z-index-3">
        <div v-if="topLeftImage" class="col-12 col-lg-6 position-relative text-lg-center mt-0 mt-lg-5 mb-5 mb-lg-0 order-2 order-lg-1">
          <figure class="figure with-shadow w-sm-85">
            <NuxtImg
							loading="lazy"
							:src="imageDefault(topLeftImage)[0]"
							:width="imageDefault(topLeftImage)[1]"
							:height="imageDefault(topLeftImage)[2]"
							class="figure-img img-fluid rounded w-sm-85" 
							:alt="imageTitle(topLeftImage)"
							:size="imageSizes(topLeftImage)"
						/>
          </figure>

          <NuxtImg 
						v-if="centralImage" 
						:src="imageDefault(centralImage)[0]"
						:width="imageDefault(centralImage)[1]"
						:height="imageDefault(centralImage)[2]"
						class="img-small-over d-inline d-lg-none rounded"
						:alt="imageTitle(centralImage)"
						:size="imageSizes(centralImage)"
						loading="lazy"
					/>
        </div>

        <div class="col-12 col-lg-6 col-xl-5 d-flex order-1 order-lg-2">
          <div class="m-auto">
            <p v-if="componentData?.content_about_template_context" class="h4" v-html="componentData?.content_about_template_context"></p>
            <h2 v-if="componentData?.content_about_template_title" class="line-h-50" v-html="componentData?.content_about_template_title"></h2>
          </div>
        </div>
      </div>

      <div class="row g-5 img-overlapped-div z-index-2">
        <div class="col-12 col-lg-4 col-xl-3 text-center text-lg-start py-lg-100">
          <AboutTemplateItem
						v-if="repeaterItems[0]"
						:data="repeaterItems[0]"
				  />       
        </div>

        <div class="col-12 col-lg-4 col-xl-3 offset-xl-1 align-self-end">
          <div class="text-center text-lg-start">
						<NuxtImg 
							v-if="centralImage" 
							:src="imageDefault(centralImage)[0]"
							:width="imageDefault(centralImage)[1]"
							:height="imageDefault(centralImage)[2]"
							class="w-100 h-auto d-none d-lg-inline mb-lg-5 rounded" 
							:alt="imageTitle(centralImage)"
							:size="imageSizes(centralImage)"
							loading="lazy"
						/>

            <AboutTemplateItem
							v-if="repeaterItems[1]"
							:data="repeaterItems[1]"
            />  
          </div>
        </div>

        <div class="col-12 col-lg-4 col-xl-3 offset-xl-1 align-self-end">
          <div class="text-center text-lg-start mb-4">
            <AboutTemplateItem
            v-if="repeaterItems[2]"
            :data="repeaterItems[2]"
            />  
          </div>

					<NuxtImg 
						v-if="bottomRightImage" 
						:src="imageDefault(bottomRightImage)[0]"
						:width="imageDefault(bottomRightImage)[1]"
						:height="imageDefault(bottomRightImage)[2]"
						class="img-fluid rounded d-none d-lg-block"
						:alt="imageTitle(bottomRightImage)"
						:size="imageSizes(bottomRightImage)"
						loading="lazy"
					/>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
	import { 
		imageDefault, 
		imageTitle, 
		imageSizes,
	} from '../../../utils/image.js';

	const props = defineProps({
		data: Object,
    index: Number,
	});

	// data
	const componentData = props?.data?.data || {};
	const repeaterItems = componentData?.content_about_template_repeater || {};
  const getBackground = componentData?.content_about_template_container_background;
  const getBacteria = componentData?.content_about_template_container_add_bacteria || "0";
  const topLeftImage = componentData?.content_about_template_top_left_image || '';
  const centralImage = componentData?.content_about_template_central_image || '';
  const bottomRightImage = componentData?.content_about_template_bottom_right_image || '';
  const anchor = props?.data?.anchor || '';
	const indexBlock = props?.index || '1';
	
	// images
  // let getCentralImage = ref(getImageSrc(centralImage) || '');
  // let getBottomRightImage = ref(getImageSrc(bottomRightImage) || '');
	 //console.log(' ');
	 // console.log(' ');
	 //console.log(' ');
	 //console.log('-- about-template --');
	 //console.log('componentData: ', componentData);
</script>

<style lang="scss" scoped>
	.block-about-us {
		.did-you-know {
			left: 52%;
		}

		.img-overlapped {
			position: relative;
			margin: -37px auto -65px;
			display: block;
			z-index: 0;
		}
	}

	.z-index-2 {
		z-index: 2;
	}

	.z-index-3 {
		z-index: 3;
	}

	// min-width 668px
	@media (min-width: 668px) {
		.block-about-us {
			.did-you-know {
				left: 70%;
			}

			.img-overlapped {
				width: 400px;
			}
		}
	}

	// min-width 768px
	@media (min-width: 768px) {
		.block-about-us {
			.did-you-know {
				left: 55%;
			}
		}
	}

	// min-width 992px
	@media (min-width: 992px) {
		.block-about-us {
			.img-overlapped {
				position: absolute;
				bottom: -394px;
				right: -183px;
				margin: 0;
			}

			.did-you-know {
				left: 0;
			}
		}
		
		.line-h-50 {
			line-height: 3.125rem;
		}
	}

	// min-width 1300px
	@media (min-width: 1300px) {
		.block-about-us {
			.img-overlapped {
				right: -179px;
				bottom: -277px;
			}
		}
	}
</style>